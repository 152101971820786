// NGC-872 - Update Title to include Inclusion Start and INclusion End Date
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../../styles/index.scss'
//Props
import PropTypes from 'prop-types'
import { description } from '../../settings/RecordPropTypes'
//Components
import { Alert } from '../../Alert/Alert'
import { Table } from '../../Table/Table'
//Helpers
import { useSafeAsync } from '../../../helper-functions/query-functions'
import { returnIconAndLabelFromString } from '../../../helper-functions/metadata-functions'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import { SEARCH_URL } from '../../settings/globals'
import BasicLink from '../../BasicLink'
import Prose from '../../utilities/Prose'
import Card from '../../Card/Card'

import { IIIF_PATH_THUMB } from '../../settings/globals'
import { convertImageUrlToIiif } from '../../../helper-functions/string-functions'

/**
 * Related record are those which related in some way to the current record.
 * For people, related record include those to which the person has contributed, created, provided, or of which they were the subject.
 */
export const ChildRecords = ({
  controlGroup,
  setSearchWithin,
  record,
  ...props
}) => {
  if (!record || !record.naId) return false
  const safeAsync = useSafeAsync()
  //Child Record States
  const [children, setChildren] = useState([])
  const [childrenLoading, setChildrenLoading] = useState(true)
  const [childrenError, setChildrenError] = useState(null)
  const [localIdCount, setLocalIdCount] = useState(0)
  //Table Pagination States
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  useEffect(() => {
    if (children) setChildren([])
    handleSetChildren()
  }, [record])

  const handleSetPage = (num) => {
    setPage(num)
  }

  const handleSetChildren = () => {
    setChildrenError(null)
    setChildrenLoading(true)

    let cGroup = ''
    if (controlGroup) cGroup = `&controlGroup=${controlGroup}`
    safeAsync(
      fetch(
        `${SEARCH_URL}/records/parentNaId/${
          record.naId
        }?abbreviated=true&limit=10&sort=naId:asc&page=${page || 1}${cGroup}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw response
        })
        .then((data) => {
          const count = data.body.hits.total.value
          if (count == 0) return false
          setLocalIdCount(data.body.aggregations.localIdentifierCount.doc_count)
          let results = []
          data.body.hits.hits.map((result, index) => {
            let custom_title = ''
            const subtitle = result._source.record.subtitle
            if (result._source.record.levelOfDescription != 'series') {
              custom_title = result._source.record.title
            } else {
              const monthNames = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ]
              const startDate =
                result._source.record.inclusiveStartDate.logicalDate.toString()
              const endDate = result._source.record.inclusiveEndDate.logicalDate
              const splitStartDate = startDate.split('-')
              const splitEndDate = endDate.split('-')
              const startMonth = monthNames[splitStartDate[1] - 1]
              const endMonth = monthNames[splitEndDate[1] - 1]
              const newStartDate = `${startMonth} ${splitStartDate[2]}, ${splitStartDate[0]}`
              const newEndDate = `${endMonth} ${splitEndDate[2]}, ${splitEndDate[0]}`
              custom_title =
                result._source.record.title +
                ', ' +
                newStartDate +
                ' - ' +
                newEndDate
            }

            results.push({
              col1: {
                level: result._source.record.generalRecordsTypes[0],
                title: custom_title,
                subtitle: subtitle,
                url:
                  result.fields.firstDigitalObject[0].objectUrl &&
                  convertImageUrlToIiif(
                    result.fields.firstDigitalObject[0].objectUrl
                  ) + IIIF_PATH_THUMB,
                naId: result._source.record.naId,
                controlGroup: String(controlGroup),
              },
              col2: returnIconAndLabelFromString(
                result._source.record.levelOfDescription
              ).label,
              col3: result._source.record.naId,
              col4: result._source.record.localIdentifier,
            })
          })
          setChildren(results)
          setPages(Math.ceil(count / 10))
          setTimeout(function () {
            setChildrenLoading(false)
          }, 500)
        })
        .catch((error) => {
          console.error('Error fetching data: ', error)
          setChildrenError(error)
        })
    )
  }

  useEffect(() => {
    setSearchWithin(children.length > 0)
  }, [children])

  const columns = useMemo(
    () => [
      {
        Header: 'Record Title',
        accessor: 'col1',
        Cell: (cell) => {
          return (
            <div
              data-naid={cell.value.naId}
              className={['display-flex', 'flex-align-center'].join(' ')}
            >
              <div
                className={[
                  'bg-primary-lighter',
                  'display-block',
                  'height-full',
                  'margin-right-1',
                  'nac-icon',
                  'nac-icon--bg-80',
                  'nac-icon--lg',
                  'nac-icon--thick',
                  'nac-icon--primary-light',
                  `nac-icon--${
                    returnIconAndLabelFromString(cell.value.level).icon
                  } `,
                  'position-relative',
                ].join(' ')}
              ></div>
              <Link
                className={`theme-white--link ellipsed-line-2`}
                data-testid={`nac-child-link--${cell.value.naId}`}
                to={{
                  pathname: '/id/' + cell.value.naId,
                }}
                state={{
                  controlGroup: String(cell.value.controlGroup),
                }}
              >
                {String(
                  `${cell.value.title} ${
                    cell.value.subtitle ? `: ${cell.value.subtitle}` : ''
                  }`
                )}
              </Link>
            </div>
          )
        },
        id: 'title',
      },
      {
        Header: 'Level',
        accessor: 'col2',
        id: 'level',
        className: 'width-15',
      },
      {
        Header: 'National Archives ID',
        accessor: 'col3',
        id: 'naId',
        className: 'width-15',
      },
      {
        Header: 'Local ID',
        accessor: 'col4',
        id: 'localId',
        className: 'width-15',
      },
    ],
    []
  )

  useEffect(() => {
    if (!record) return false
    if (
      !record.authorityType &&
      record.levelOfDescription &&
      record.levelOfDescription !== 'item' &&
      (!record.digitalObjects || record.digitalObjects.length == 0)
    )
      handleSetChildren()
  }, [page])

  return record.levelOfDescription &&
    record.levelOfDescription !== 'item' &&
    (!record.digitalObjects || record.digitalObjects.length == 0) &&
    children.length > 0 ? (
    <section className="bg-base-lighter-opacity-50">
      <div className="maxw-desktop-lg margin-x-auto">
        <div>
          <h2>
            Inside this{' '}
            {returnIconAndLabelFromString(record.levelOfDescription).label}
          </h2>
          <Alert>
            The following table represents the next level of record within this{' '}
            {returnIconAndLabelFromString(record.levelOfDescription).label},
            which includes{' '}
            <strong>
              {record.seriesCount &&
                `${returnNumberWithCommas(record.seriesCount)} Series`}
            </strong>
            {record.seriesCount && record.fileUnitCount && ', '}
            <strong>
              {record.fileUnitCount &&
                `${returnNumberWithCommas(record.fileUnitCount)} File Unit${
                  record.fileUnitCount > 1 ? 's' : ''
                }`}
            </strong>
            {((record.seriesCount && record.itemCount) ||
              (record.fileUnitCount && record.itemCount) ||
              (record.fileUnitCount && record.seriesCount)) &&
              ' and '}
            <strong>
              {record.itemCount &&
                `${returnNumberWithCommas(record.itemCount)} Item${
                  record.itemCount > 1 ? 's' : ''
                }`}
            </strong>
            .{' '}
            {record.levelOfDescription !== 'fileUnit' &&
              (record.seriesCount || record.fileUnitCount) &&
              'Each of these records may additionally hold more records.'}
          </Alert>
          <Alert type="warning" data-testid="nac-related-records--alert">
            <Prose>
              {' '}
              Note: The table below is a sample of the contents found within
              this{' '}
              {returnIconAndLabelFromString(record.levelOfDescription).label}.
            </Prose>
            <Prose>
              To access more and to search within the{' '}
              {returnIconAndLabelFromString(record.levelOfDescription).label}{' '}
              <BasicLink
                to={{
                  pathname: `/search-within/${record.naId}`,
                }}
              >
                click here
              </BasicLink>
              .
            </Prose>
          </Alert>
        </div>
        <div className="tablet:padding-x-3 margin-x-neg-2 tablet:margin-x-neg-0">
          <div className="overflow-x-scroll padding-bottom-2 tablet:padding-bottom-0">
            <Card
              className={[
                'padding-0',
                'tablet-lg:padding-0',
                'margin-x-2',
                'tablet:margin-x-0',
              ].join(' ')}
              style={{ minWidth: '36rem' }}
            >
              <Table
                hideColumns={!localIdCount ? ['localId'] : null}
                columns={columns}
                data={children}
                id="nac-child-records-table"
                pageCount={pages}
                pageNum={page}
                setPage={handleSetPage}
                loading={childrenLoading}
              />
            </Card>
          </div>
        </div>
      </div>
    </section>
  ) : (
    ''
  )
}

ChildRecords.defaultProps = {
  record: [],
}

ChildRecords.propTypes = {
  controlGroup: PropTypes.string,
  /**
   * The entire data object for a single record
   */
  record: description,
  setSearchWithin: PropTypes.func,
}
