// import React from 'react'
import React, { useContext } from 'react'
import UserContext from '../../../contexts/UserContext'
import { Button } from '../../Button/Button'

import '../../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import PrintPage from '../../utilities/PrintPage'
import Section from '../../description/section'
import { PageHeader } from '../../Templates/PageHeader/PageHeader'
import { Icon } from '@mui/material'

const HelpCenterWrapper = ({ children, ...props }) => {
  const { login } = useContext(UserContext) || {}
  sessionStorage.setItem('titles', 'National Archives Catalog')

  const theme = 'primary-darker'
  return (
    <PrintPage>
      <main className={['theme-white', 'width-full'].join(' ')}>
        <div
          className={[
            'minh-full',
            'flex-align-center',
            'flex-justify-center',
            'flex-row',
            'grid-row',
          ].join(' ')}
        >
          <div
            className={['display-flex', 'flex-column', 'width-full'].join(' ')}
          >
            <section
              className={[
                'overflow-visible',
                // 'page-header-wrapper',
                'padding-y-6',
                'shadow-xs',
                'text-center',
                `theme-${theme}`,
                `theme-${theme}--bg`,
                `theme-${theme}--text`,
                'width-full',
              ].join(' ')}
            >
              <div
                className={[
                  'page-header',
                  //
                  'display-flex',
                  'flex-column',
                  'flex-gap',
                  'grid-col',
                  'maxw-desktop-lg',
                  'margin-x-auto',
                  'width-full',
                ].join(' ')}
              >
                <h1
                  className={[
                    'clearfix',
                    'page-title',
                    'width-full',
                    `theme-${theme}--text`,
                  ].join(' ')}
                  data-testid="nac-page-header--title"
                  title="Help Center"
                >
                  Help Center
                </h1>
                How can we help you today?
              </div>
            </section>

            <div
              className={[
                'bg-gray-10',
                'display-flex',
                'flex-column',
                'flex-no-wrap',
                'grid-col',
                'minh-1',
                'padding-x-0',
                'text-base-darker',
              ].join(' ')}
            >
              <Section data-testid="nac-help__faqs">
                <div
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-lg',
                    'margin-x-auto',
                    'maxw-desktop',
                    'width-full',
                  ].join(' ')}
                >
                  {children}
                </div>
              </Section>
            </div>
          </div>
          <Button
            data-testid="nac-header--login"
            displayClass="flex"
            onClick={login}
            reduced
            thin
            theme="primary"
            size="xs"
            className="opacity-0 margin-left-auto"
          >
            <span>Log in / Sign Up</span>
          </Button>
        </div>
      </main>
    </PrintPage>
  )
}

HelpCenterWrapper.defaultProps = {}

HelpCenterWrapper.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
}

export default HelpCenterWrapper
