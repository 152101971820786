import UserContext from '../../contexts/UserContext'
import useHttp from '../../hooks/use-http'
import DOMPurify from 'dompurify'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
//Context
import AccountContext from '../../contexts/AccountContext'
import {
  audioTypes,
  imageTypes,
  videoTypes,
} from '../../helper-functions/metadata-functions'
import { convertCamelToSentence } from '../../helper-functions/string-functions'
import useInput from '../../hooks/use-input'
import {
  removeExtraWhiteSpace,
  validateInputFromSchema,
} from '../form/Validate/Validate'
import {
  DEFAULT_FEED_PAGE,
  DEFAULT_CONTRIBUTION_FEED_LIMIT,
  SEARCH_URL,
  SERVER_URL,
} from '../settings/globals'
import { useLocation } from 'react-router-dom'

/**
 * Debouncing is the act of making a function fire only once in a certain time interval.
 * This reusable hook allows use to prevent too many API calls from occuring consecutively, for example.
 */
const useAccount = (value, delay, cancel) => {
  const { pathname } = useLocation()
  const { userName } = useParams()

  const { accountsAccessArray, loggedInUser, isLoggedInUsersPage } =
    useContext(UserContext)

  // State
  const [account, setAccount] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const [contributionTotals, setContributionTotals] = useState(null)
  const [notificationTotals, setNotificationTotals] = useState(null)
  const [lastViewedNotiesAt, setLastViewedNotiesAt] = useState(null)
  const [uniqueTags, setUniqueTags] = useState(null)
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    if (!userName || loading) return false
    if (uniqueTags?.userName !== userName) {
      setUniqueTags(null)
    }
    const abortController = new AbortController()
    setLoading(true)
    setContributionTotals(null)
    requestAccountHandler(abortController)

    return () => {
      abortController.abort()
      setAccount(null)
    }
  }, [loggedInUser, userName])

  const handleUserResponse = (result) => {
    const loggedInUserString = localStorage.getItem('loggedInUser')
    const loggedInUser = JSON.parse(loggedInUserString)
    const loggedInUserrole = loggedInUser.role

    const resultUser = result.userName
    const resultUserRole = result.role

    if (loggedInUserrole == 'administrator') {
      if (resultUser == loggedInUser.userName) {
        setAccount(result)
        setUserId(result?.userId)
      } else if (
        resultUser !== loggedInUser.userName &&
        (resultUserRole == 'contributor' ||
          resultUserRole == 'moderator' ||
          resultUserRole == 'administrator')
      ) {
        setAccount(result)
        setUserId(result?.userId)
      } else {
        accountErrorHandler({
          id: 'DEFAULT',
        })
        return
      }
    } else if (
      loggedInUserrole == 'moderator' ||
      loggedInUserrole == 'contributor'
    ) {
      if (resultUser == loggedInUser.userName) {
        setAccount(result)
        setUserId(result?.userId)
      } else {
        accountErrorHandler({
          id: 'DEFAULT',
        })
        return
      }
    } else {
      accountErrorHandler({
        id: 'DEFAULT',
      })
      return
    }

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const accountErrorHandler = (error) => {
    if (error) setError(error)
  }

  const { sendRequest: requestAccountHandler } = useHttp(
    {
      url: `${SERVER_URL}/users/userName/${userName}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleUserResponse,
    accountErrorHandler
  )

  /**
   * CONTRIBUTION TOTALS
   */

  //State
  const [errorTotals, setErrorTotals] = useState(null)
  const [loadingTotals, setLoadingTotals] = useState(false)
  const [lastRequestedTimestamp, setLastRequestedTimestamp] = useState(null)
  const [latestContributionTimestamp, setLatestContributionTimestamp] =
    useState(null)

  /**
   * Load public totals by default for non-logged in users
   */
  useEffect(() => {
    if (!account?.userId) return false
    if (
      !contributionTotals ||
      contributionTotals?.userName !== account.userName
    ) {
      setLoadingTotals(true)
      requestLastViewedNotiesHandler()
      requestContributionTotalsHandler()
    }
  }, [account])

  /**
   * If the user is logged in, load the totals at the appropriate level of detail
   */
  useEffect(() => {
    if (!account?.userId) return false
    if (
      accountsAccessArray.accessGrantedToAccountDetails &&
      !(parseInt(contributionTotals?.totals?.totalThisYear) >= 0)
    ) {
      setLoadingTotals(true)
      requestLastViewedNotiesHandler()
      requestContributionTotalsHandler()
    }
  }, [loggedInUser])

  const contributionTotalsResponseHandler = (results) => {
    setLastRequestedTimestamp(results.responseTimestamp)
    setContributionTotals({
      totals: results[0],
      userName: account.userName,
    })
    setTimeout(() => {
      setLoadingTotals(false)
    }, [500])
  }

  const errorHandler = (error) => {
    setErrorTotals('error', error)
  }

  const { sendRequest: requestContributionTotalsHandler } = useHttp(
    {
      /** TO DO: Set up endpoint for userName!!! */
      url: `${SEARCH_URL}/contributions/userId/${
        account?.userId /*userName*/
      }?report=userTotals${
        accountsAccessArray.accessGrantedToAdminAccountDetails
          ? '&expanded=true'
          : !isLoggedInUsersPage
          ? '&abbreviated=true'
          : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    contributionTotalsResponseHandler,
    errorHandler
  )

  const notificationTotalsResponseHandler = (results) => {
    setNotificationTotals(results)

    setTimeout(() => {
      setLoadingTotals(false)
    }, [500])
  }

  const { sendRequest: requestNotificationTotalsHandler } = useHttp(
    {
      url: `${SEARCH_URL}/users/notifications/${account?.userId}?countOnly=true&date=${lastViewedNotiesAt}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    notificationTotalsResponseHandler,
    errorHandler
  )

  const lastViewedNotiesResponseHandler = (results) => {
    const date = new Date(results.lastViewedNotificationsAt)
    const dateFormatted = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0]
    setLastViewedNotiesAt(dateFormatted)
  }

  useEffect(() => {
    if (account) {
      requestNotificationTotalsHandler()
    }
  }, [lastViewedNotiesAt])

  const { sendRequest: requestLastViewedNotiesHandler } = useHttp(
    {
      url: `${SEARCH_URL}/users/lastViewedNotificationsAt/${account?.userId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    lastViewedNotiesResponseHandler,
    errorHandler
  )

  return {
    account,
    contributionTotals,
    notificationTotals,
    lastViewedNotiesAt,
    error,
    errorTotals,
    loading,
    loadingTotals,
    setContributionTotals,
    setUniqueTags,
    uniqueTags,
    userId,
    setAccount,
  }
}

export default useAccount

export const returnActiveObjectType = (objectType) => {
  if (!objectType) return 'Object'
  else if (imageTypes.some((element) => objectType.includes(element)))
    return 'Image'
  else if (videoTypes.some((element) => objectType.includes(element)))
    return 'Video'
  else if (audioTypes.some((element) => objectType.includes(element)))
    return 'Audio File'
  else return 'Object'
}
