import React, { Fragment, useContext } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
// Hooks
import useModal from '../../../hooks/use-modal'
// Components
import Modal, { ModalBody, ModalFooter } from '../../Modal/Modal'
import { Button } from '../../Button/Button'
import { IconLabel } from '../../IconLabel/IconLabel'
// Helpers
import { compareNumbers } from '../../../helper-functions/sort-functions'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import { returnHMSFromSeconds } from '../../../helper-functions/date-functions'
import {
  archivedCopySortOrder,
  returnCustomOrder,
} from './section_archived_copies'
import SearchContext from '../../../contexts/SearchContext'
import Highlighter from '../../Highlighter/Highlighter'

const AllCopiesModal = ({ data, ...props }) => {
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const { isShowing, toggle } = useModal()

  const FormattedRunningTime = (runningTimeSeconds) => {
    const runningTime = returnHMSFromSeconds(
      parseInt(runningTimeSeconds.runningTimeSeconds)
    )
    return (
      <span>
        {runningTime.hours > 0 && (
          <Fragment>
            {runningTime.hours > 1 ? `${runningTime.hours} hours` : '1 hour'}
            {runningTime.hours > 0 && runningTime.minutes > 0
              ? runningTime.seconds > 0
                ? ', '
                : ' and '
              : ''}{' '}
          </Fragment>
        )}
        {runningTime.minutes > 0 && (
          <Fragment>
            {runningTime.minutes > 1
              ? `${runningTime.minutes} minutes`
              : '1 minute'}
            {(runningTime.hours > 0 || runningTime.minutes > 0) &&
            runningTime.minutes > 0
              ? ' and '
              : ''}{' '}
          </Fragment>
        )}
        {runningTime.seconds > 1
          ? `${runningTime.seconds} seconds`
          : '1 second'}
      </span>
    )
  }

  return (
    <>
      <Button
        data-testid="nac-description__full-details-modal-button"
        onClick={toggle}
        outline
        reduced
        size="2xs"
        srText={`Access full details for all copies.`}
        theme="base-darker"
        thin
      >
        Full Details
      </Button>
      {isShowing && (
        //TO DO: Title that makes more sense....
        <Modal
          hide={toggle}
          id={`full-details`}
          size="lg"
          title={`Full Details`}
        >
          <ModalBody id={`full-details`}>
            <div data-testid="nac-description__full-details-modal">
              {data.map((copy, index) => (
                <div
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-sm',
                  ].join(' ')}
                  key={index}
                >
                  <strong>Copy {index + 1}</strong>
                  <div
                    className={['display-flex', 'flex-row', 'flex-gap'].join(
                      ' '
                    )}
                  >
                    <div
                      className={[
                        'display-flex',
                        'flex-column',
                        'width-12',
                      ].join(' ')}
                    >
                      Purpose:
                    </div>
                    <div
                      className={[
                        'display-flex',
                        'flex-column',
                        'flex-gap-sm',
                        'width-full',
                      ].join(' ')}
                    >
                      {copy.details.data.copyStatus
                        .split('-')
                        .sort(returnCustomOrder(archivedCopySortOrder, 'index'))
                        .map((status) => (
                          <Fragment key={status}>
                            <IconLabel
                              color="base-darkest"
                              iconName={status.toLowerCase()}
                              iconSize="xs"
                            >
                              <strong>{status}</strong>
                            </IconLabel>
                            {copyStatusDescriptions.map((desc, index) => (
                              <Fragment key={desc.label + index}>
                                {status === desc.label && (
                                  <span className="margin-left-3">
                                    {desc.description}
                                  </span>
                                )}
                              </Fragment>
                            ))}
                          </Fragment>
                        ))}
                    </div>
                  </div>
                  {copy.details.data.mediaOccurrences && (
                    <div
                      className={['display-flex', 'flex-row', 'flex-gap'].join(
                        ' '
                      )}
                    >
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'width-12',
                        ].join(' ')}
                      >
                        Types of Media:
                      </div>
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'flex-gap',
                          'margin-left-3',
                          'width-full',
                        ].join(' ')}
                      >
                        {copy.details.data.mediaOccurrences.map(
                          (occurrence, i) => (
                            <div
                              key={`mediaOccurrence-${i}`}
                              className={[
                                'display-flex',
                                'flex-column',
                                'flex-gap-xs',
                              ].join(' ')}
                            >
                              <strong>{occurrence.specificMediaType}</strong>
                              {mediaOccurrencesDescriptions.map(
                                (desc, index) => (
                                  <Fragment key={desc.label + index}>
                                    {occurrence[desc.id] && (
                                      <span>
                                        {desc.id == 'runningTime' ? (
                                          <div>
                                            Running Time:{' '}
                                            <FormattedRunningTime
                                              runningTimeSeconds={
                                                occurrence.runningTime
                                              }
                                            />
                                          </div>
                                        ) : (
                                          desc.label +
                                          ': ' +
                                          occurrence[desc.id]
                                        )}
                                      </span>
                                    )}
                                  </Fragment>
                                )
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  {copy.totalRunningTime.runtime && (
                    <div
                      className={['display-flex', 'flex-row', 'flex-gap'].join(
                        ' '
                      )}
                    >
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'width-12',
                        ].join(' ')}
                      >
                        Total Running Time:
                      </div>
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'flex-gap-xs',
                          'margin-left-3',
                          'width-full',
                        ].join(' ')}
                      >
                        <p>
                          {copy.totalRunningTime.hours > 0 && (
                            <Fragment>
                              {copy.totalRunningTime.hours > 1
                                ? `${copy.totalRunningTime.hours} hours`
                                : '1 hour'}
                              {copy.totalRunningTime.hours > 0 &&
                              copy.totalRunningTime.minutes > 0
                                ? copy.totalRunningTime.seconds > 0
                                  ? ', '
                                  : ' and '
                                : ''}{' '}
                            </Fragment>
                          )}
                          {copy.totalRunningTime.minutes > 0 && (
                            <Fragment>
                              {copy.totalRunningTime.minutes > 1
                                ? `${copy.totalRunningTime.minutes} minutes`
                                : '1 minute'}
                              {(copy.totalRunningTime.hours > 0 ||
                                copy.totalRunningTime.minutes > 0) &&
                              copy.totalRunningTime.minutes > 0
                                ? ' and '
                                : ''}{' '}
                            </Fragment>
                          )}
                          {copy.totalRunningTime.seconds > 1
                            ? `${copy.totalRunningTime.seconds} seconds`
                            : '1 second'}
                        </p>
                      </div>
                    </div>
                  )}
                  {copy.totalFootage.length && (
                    <div
                      className={['display-flex', 'flex-row', 'flex-gap'].join(
                        ' '
                      )}
                    >
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'width-12',
                        ].join(' ')}
                      >
                        Total Footage Length:
                      </div>
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'flex-gap-xs',
                          'margin-left-3',
                          'width-full',
                        ].join(' ')}
                      >
                        {returnNumberWithCommas(copy.totalFootage.length)}
                      </div>
                    </div>
                  )}
                  {copy.details.data.extent && (
                    <div
                      className={['display-flex', 'flex-row', 'flex-gap'].join(
                        ' '
                      )}
                    >
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'width-12',
                        ].join(' ')}
                      >
                        Extent:
                      </div>
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'margin-left-3',
                          'width-full',
                        ].join(' ')}
                      >
                        <p>
                          <Highlighter search={q}>
                            {copy.details.data.extent}
                          </Highlighter>
                        </p>
                      </div>
                    </div>
                  )}
                  {copy.details.data.holdingsMeasurements && (
                    <div
                      className={['display-flex', 'flex-row', 'flex-gap'].join(
                        ' '
                      )}
                    >
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'width-12',
                        ].join(' ')}
                      >
                        Count:
                      </div>
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'flex-gap-xs',
                          'margin-left-3',
                          'width-full',
                        ].join(' ')}
                      >
                        {copy.details.data.holdingsMeasurements.map(
                          (item, i) => (
                            <p key={`holdingsMeasurements-${i}`}>
                              {(item.count ? item.count : '') +
                                ' ' +
                                (item.type ? item.type : '')}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  {copy.details.data.physicalOccurrenceNote && (
                    <div
                      className={['display-flex', 'flex-row', 'flex-gap'].join(
                        ' '
                      )}
                    >
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'width-12',
                        ].join(' ')}
                      >
                        Notes:
                      </div>
                      <div
                        className={[
                          'display-flex',
                          'flex-column',
                          'margin-left-3',
                          'width-full',
                        ].join(' ')}
                      >
                        <p>{copy.details.data.physicalOccurrenceNote}</p>
                      </div>
                    </div>
                  )}
                  {/* Horizontal line between copies */}
                  {index < data.length - 1 && (
                    <hr className={['margin-2'].join(' ')} />
                  )}
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter hide={toggle} />
        </Modal>
      )}
    </>
  )
}
AllCopiesModal.propTypes = {
  data: PropTypes.array,
}

export default AllCopiesModal

// This information is detailed in the Swagger data model documentation
const mediaOccurrencesDescriptions = [
  {
    id: 'base',
    label: 'Base',
    description:
      'The substance of the media used to physically carry or hold the information in the archival item. Also called the substrate.',
    //'Provides the physical characteristics of the archival materials, which helps establish conservation, storage, retrieval, reproduction, and use requirements.',
    examples: [
      'Photographic Paper: Resin-coated',
      'Cellulose Acetate',
      'Paper',
    ],
  },
  {
    id: 'color',
    label: 'Color',
    description:
      'The absence or presence of colors, besides black, white and gray, of the specific media type.',
    examples: ['Color', 'Black-and-White'],
  },
  {
    id: 'containerId',
    label: 'Container Id',
    description:
      'The identifier or number for the individual container storing each specific media type.',
    examples: ['Box 6829', '27'],
  },
  {
    id: 'depth',
    label: 'Depth',
    description:
      'The distance in inches measured from front to back of a specific media type.',
    examples: [6.75, 10],
  },
  {
    id: 'dimension',
    label: 'Dimension',
    description:
      'The standard size of the physical items of a specific media type. Provides information needed for reproduction.',
    examples: ['3x5 inch', '1/2 inch', 'Varied'],
  },
  {
    id: 'elementNumber',
    label: 'Element Number',
    description:
      'Indicates the sequence of the media occurrence in relation to the other media occurrences that make up the physical occurrence. Specifically, it is the number assigned to an individual physical component that makes up a reel, tape, or disc. Commonly used when a film reel is made up of separate sound and picture elements, or when a film reel is composed of A and B rolls. For example, a film reel may be composed of an image reel and a soundtrack reel. The image reel could be designated element number 1, and the soundtrack reel could be designated element number 2.',
    examples: [1, 2, 3],
  },

  {
    id: 'emulsion',
    label: 'Emulsion',
    description:
      'The type of coating that is bonded to and supported by the base of the specific media type.',
    examples: ['Metal Evaporation', 'Ferric Oxide'],
  },
  {
    id: 'footage',
    label: 'Footage',
    description:
      'The length in feet for an individual roll or reel in an audiovisual item.',
    examples: [502, 465],
  },
  {
    id: 'format',
    label: 'Format',
    description:
      'The format of the media comprising the audiovisual materials. For example, the format of videotape can be VHS or Betamax, the format for sound recordings can be Master or Stamper Matrix, or the format for motion pictures can be Duplicate Negative Composite or Master Projection Print Composite.',
    examples: ['Dication Belt: Magnabelt', 'Sound: Mass Produced Pressing'],
  },
  {
    id: 'height',
    label: 'Height',
    description:
      'The distance in inches measured from top to bottom of the specific media type.',
    examples: [11.75, 3],
  },
  {
    id: 'otherPreservationCharacteristics',
    label: 'Other Preservation Characteristics',
    description:
      'Additional information about the physical nature of the archival materials needed for preservation purposes.',
    examples: ['Artifacts: Copper alloy', 'Fastners/records adhered together'],
  },
  {
    id: 'mediaOccurrenceNote',
    description:
      'Explanations or significant information relating to the media occurrence of the archival materials.',
    examples: [
      'This reel contains music and sound effects.',
      'These images were made by the albumen and collodion processes.',
    ],
    label: 'Media Occurrence Note',
  },
  {
    id: 'physicalRestrictionNote',
    description:
      'Information regarding any physical restrictions that apply to the particular specific media type of the archival materials. Physical restrictions are primarily preservation restrictions.',
    examples: [
      'Because these records are stored offsite, researchers must request the records 48 hours in advance.',
      'This film reel has shrunk and may not be viewed.',
    ],
    label: 'Physical Restriction Note',
  },
  {
    id: 'pieceCount',
    description:
      ' The exact number of physical items comprising the specific media type of the archival materials.',
    examples: ['3', '10'],
    label: 'Piece Count',
  },
  {
    id: 'process',
    description:
      ' The technical or manual means by which the specific media type was created.',
    examples: ['Artwork: Chalk', 'Photocopy'],
    label: 'Process',
  },
  {
    id: 'recordingSpeed',
    label: 'Recording Speed',
    description:
      'The speed at which audio recordings are recorded or reproduced. For audio tapes, the measurement is inches per second (ips). For audio discs or cylinders, the measurement is revolutions per minute (rpm).',
    examples: ['Audio Disk: 120 rpm', 'Audio Tape: 15 ips'],
  },
  {
    id: 'reelTapeDiscNumber',
    label: 'Reel Tape Disc Number',
    description: null,
    examples: [44, 3],
  },
  {
    id: 'roll',
    label: 'Roll',
    description:
      'Indication of the roll used in a motion picture. In creating a film, alternate scenes are typically assembled in checkerboard fashion on two or more different rolls with black leaders between scenes to facilitate fades and dissolves and to ultimately produce a single strip master positive from which a duplicating negative (dupe neg) can then be made.',
    examples: ['A Roll', 'B Roll'],
  },
  {
    id: 'runningTime',
    label: 'Running Time',
    description:
      'The duration in seconds of the individual parts (reels, tapes, rolls) of an audiovisual item.',
    examples: [7200, 290],
  },
  {
    id: 'soundtrackConfiguration',
    label: 'Sound Track Configuration',
    description:
      'The recording configuration of a sound recording or motion picture soundtrack.',
    examples: ['Film: Variable Area, Push-pull', 'Sound: Dual'],
  },
  {
    id: 'soundtrackLanguage',
    label: 'Sound Track Language',
    description:
      'The language of the soundtrack for a motion picture or video recording.',
    examples: ['Dutch', 'German'],
  },
  {
    id: 'tapeThickness',
    label: 'Tape Thickness',
    description:
      'The thickness of the tape on which the audiovisual item has been recorded.',
    examples: ['0.5 mil', '1.0 mil'],
  },
  {
    id: 'totalFootage',
    label: 'Total Footage',
    description: '',
  },
  {
    id: 'technicalAccessRequirementsNote',
    description:
      'A description of the equipment needed to view the specific media type, such as a light table to view aerial film, or hardware and software to view archival materials on electronic media.',
    examples: [
      'An open reel computer tape drive is required.',
      'A light table is needed to view these aerial photographs.',
    ],
    label: 'Technical Access Requirements Note',
  },
  {
    id: 'wind',
    label: 'Wind',
    description: 'The playback direction of the reel/roll.',
    examples: ['A Wind', 'B Wind'],
  },
]

const copyStatusDescriptions = [
  {
    label: 'Reference',
    description: 'Used for consultation by both NARA staff and researchers.',
  },
  {
    label: 'Reproduction',
    description:
      'Used to create exact copies of the record in form and content, but not necessarily in size and appearance. Also known as the "intermediate copy," especially in motion picture film descriptions.',
  },
  {
    label: 'Preservation',
    description:
      'Used in the processes and operations involved in the stabilization and protection of records against damage or deterioration.',
  },
]
